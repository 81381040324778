import { defineStore } from 'pinia';
import router from '../router'
import { Sesion } from '../Api/API_Concentrador'
import axios from '../axios';

export const useAuthStore = defineStore({
    id: 'auth',
    state: () => ({
        // initialize state from local storage to enable user to stay logged in
        usuario: localStorage.getItem('usuario') ? JSON.parse(localStorage.getItem('usuario')) : JSON.parse(sessionStorage.getItem('usuario')),
        access_token: localStorage.getItem('access_token') ? localStorage.getItem('access_token') : sessionStorage.getItem('access_token'),
        refresh_token: localStorage.getItem('refresh_token') ? localStorage.getItem('refresh_token') : sessionStorage.getItem('refresh_token'),
        rememberMe: localStorage.getItem('rememberMe') ? localStorage.getItem('rememberMe') : sessionStorage.getItem('rememberMe'),
    }),
    actions: {
        async login(email, password, rememberMe) {
            try {
                const res = await Sesion.iniciarSesion(email, password)

                // update pinia state
                this.usuario = {
                    // email: email,
                    // roles: res.groups,
                    userName: res.userName,
                    id: res.id
                }

                this.access_token = res.token;
                this.refresh_token = res.refreshToken;

                this.rememberMe = rememberMe;

                axios.defaults.headers.common['Authorization'] = `Bearer ${res.token}`

                let store = rememberMe ? localStorage : sessionStorage;
                let today = new Date().getTime();

                store.setItem('rememberMe', rememberMe);
                store.setItem('usuario', JSON.stringify(this.usuario));
                store.setItem('access_token', this.access_token);
                //add 5 minutes to the current time
                store.setItem('access_token_expiration', today + 60000 * 1); // 19.8 minutos
                //por lo tanto antes de que expire el token, se debe refrescar
                store.setItem('refresh_token', this.refresh_token);
                store.setItem('refresh_token_expiration', today + 86400000 * 6.8 ); // 7 dias

                //Token de Notificaciones
                // store.setItem('token_notificaciones', res.token_notificaciones);// 8 dias

                // redirect to previous url or default to home page
                router.push(this.returnUrl || '/');
            } catch (error) {
                throw error;
                //console.error(error);
            }
        },
        refreshToken(token) {
            let store = localStorage.getItem("access_token") ? localStorage : sessionStorage;

            let today = new Date().getTime();

            store.setItem("access_token", token);
            store.setItem("access_token_expiration", today + 60000 * 1); // 19.8 minutos
        },
        logout() {
            this.usuario = null;
            this.access_token = null;
            this.refresh_token = null;
            this.rememberMe = null;
            localStorage.clear()
            sessionStorage.clear()
            router.push('/login');
        }
    }
});

export default useAuthStore;