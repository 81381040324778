import axios from "axios";
import useAuthStore from './stores/auth.store'


const axiosInstance = axios.create({
    baseURL: import.meta.env.VITE_BACKEND_URL,
});

axiosInstance.interceptors.request.use(
    async config => {
        let store = localStorage.getItem("access_token") ? localStorage : sessionStorage;

        if (store.getItem("refresh_token_expiration") < new Date().getTime() && store.getItem("refresh_token") ) {
            console.log('Refresh token expirado')
            const auth = useAuthStore()
            await auth.logout()
        }

        if (store.getItem("access_token_expiration") < new Date().getTime() && store.getItem("refresh_token") ) {
            let new_access_token = await axios.post(`${import.meta.env.VITE_BACKEND_URL}api/login/refresh/`, {
                refreshToken: store.getItem("refresh_token")
            });
            
            const auth = useAuthStore()

            auth.refreshToken(new_access_token.data.token)
        }
        
        config.headers.Authorization = `Bearer ${store.getItem("access_token")}`;
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);


export default axiosInstance;