<template>
    <div class="navbar-custom">
        <div class="topbar container-fluid">
            <div class="d-flex align-items-center gap-lg-2 gap-1">

                <!-- Topbar Brand Logo -->
                <div class="logo-topbar">
                    <!-- Logo light -->
                    <a href="index.html" class="logo-light">
                        <span class="logo-lg">
                            <img src="/assets/images/logo.png" alt="logo">
                        </span>
                        <span class="logo-sm">
                            <img src="/assets/images/logo-sm.png" alt="small logo">
                        </span>
                    </a>

                    <!-- Logo Dark -->
                    <a href="index.html" class="logo-dark">
                        <span class="logo-lg">
                            <img src="/assets/images/logo-dark.png" alt="dark logo">
                        </span>
                        <span class="logo-sm">
                            <img src="/assets/images/logo-dark-sm.png" alt="small logo">
                        </span>
                    </a>
                </div>

                <!-- Sidebar Menu Toggle Button -->
                <button @click="toggleMenu" class="button-toggle-menu">
                    <i class="mdi mdi-menu"></i>
                </button>

                <!-- Horizontal Menu Toggle Button -->
                <button class="navbar-toggle" data-bs-toggle="collapse" data-bs-target="#topnav-menu-content">
                    <div class="lines">
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </button>

                <!-- Topbar Search Form -->
                <div class="app-search dropdown d-none d-lg-block">
                    <form>
                        <div class="input-group">
                            <input type="search" class="form-control dropdown-toggle" placeholder="Search..." id="top-search">
                            <span class="mdi mdi-magnify search-icon"></span>
                            <button class="input-group-text btn btn-primary" type="submit">Search</button>
                        </div>
                    </form>

                    <div class="dropdown-menu dropdown-menu-animated dropdown-lg" id="search-dropdown">
                        <!-- item-->
                        <div class="dropdown-header noti-title">
                            <h5 class="text-overflow mb-2">Found <span class="text-danger">17</span> results</h5>
                        </div>

                        <!-- item-->
                        <a href="javascript:void(0);" class="dropdown-item notify-item">
                            <i class="uil-notes font-16 me-1"></i>
                            <span>Analytics Report</span>
                        </a>

                        <!-- item-->
                        <a href="javascript:void(0);" class="dropdown-item notify-item">
                            <i class="uil-life-ring font-16 me-1"></i>
                            <span>How can I help you?</span>
                        </a>

                        <!-- item-->
                        <a href="javascript:void(0);" class="dropdown-item notify-item">
                            <i class="uil-cog font-16 me-1"></i>
                            <span>User profile settings</span>
                        </a>

                        <!-- item-->
                        <div class="dropdown-header noti-title">
                            <h6 class="text-overflow mb-2 text-uppercase">Users</h6>
                        </div>

                        <div class="notification-list">
                            <!-- item-->
                            <a href="javascript:void(0);" class="dropdown-item notify-item">
                                <div class="d-flex">
                                    <img class="d-flex me-2 rounded-circle" src="/assets/images/users/avatar-2.jpg" alt="Generic placeholder image" height="32">
                                    <div class="w-100">
                                        <h5 class="m-0 font-14">Erwin Brown</h5>
                                        <span class="font-12 mb-0">UI Designer</span>
                                    </div>
                                </div>
                            </a>

                            <!-- item-->
                            <a href="javascript:void(0);" class="dropdown-item notify-item">
                                <div class="d-flex">
                                    <img class="d-flex me-2 rounded-circle" src="/assets/images/users/avatar-5.jpg" alt="Generic placeholder image" height="32">
                                    <div class="w-100">
                                        <h5 class="m-0 font-14">Jacob Deo</h5>
                                        <span class="font-12 mb-0">Developer</span>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <ul class="topbar-menu d-flex align-items-center gap-3">
                <li class="dropdown d-lg-none">
                    <a class="nav-link dropdown-toggle arrow-none" data-bs-toggle="dropdown" href="#" role="button" aria-haspopup="false" aria-expanded="false">
                        <i class="ri-search-line font-22"></i>
                    </a>
                    <div class="dropdown-menu dropdown-menu-animated dropdown-lg p-0">
                        <form class="p-3">
                            <input type="search" class="form-control" placeholder="Search ..." aria-label="Recipient's username">
                        </form>
                    </div>
                </li>

                <li class="dropdown" ref="languageDropdown">
                    <a class="nav-link dropdown-toggle arrow-none" href="#" role="button" @click="collapseLanguage= !collapseLanguage">
                        <img :src="getFlag(selectedLanguage)" alt="user-image" class="me-0 me-sm-1" height="12">
                        <span class="align-middle d-none d-lg-inline-block">{{selectedLanguage}}</span> <i class="mdi mdi-chevron-down d-none d-sm-inline-block align-middle"></i>
                    </a>
                    <div class="dropdown-menu dropdown-menu-end dropdown-menu-animated" :class="{show : collapseLanguage}" style="position: absolute; inset: 0px 0px auto auto; margin: 0px; transform: translate3d(0.8px, 72px, 0px);">
                        <a href="javascript:void(0);" @click="changeLanguage" class="dropdown-item">
                            <img :src="getFlag(alternativeLanguage)" alt="user-image" class="me-1" height="12"> <span class="align-middle">{{alternativeLanguage}}</span>
                        </a>
                    </div>
                </li>

                <NotificationsNavBar></NotificationsNavBar>

                <li class="d-none d-sm-inline-block">
                    <div class="nav-link" id="light-dark-mode" data-bs-toggle="tooltip" data-bs-placement="left" title="Theme Mode">
                        <i class="ri-moon-line font-22"></i>
                    </div>
                </li>



                <li class="dropdown" ref="profileDropdown">
                    <a class="nav-link dropdown-toggle arrow-none nav-user px-2" @click="collapseProfile=!collapseProfile" data-bs-toggle="dropdown" href="javascript:void(0);" role="button" aria-haspopup="false" aria-expanded="false">
                        <span class="account-user-avatar">
                            <img src="/assets/images/users/avatar-1.jpg" alt="user-image" width="32" class="rounded-circle">
                        </span>
                        <span class="d-lg-flex flex-column gap-1 d-none">
                            <h5 class="my-0">{{ authStore.usuario.nombre }}</h5>
                            <h6 class="my-0 fw-normal">Administrador</h6>
                        </span>
                    </a>
                    <div class="dropdown-menu dropdown-menu-end dropdown-menu-animated profile-dropdown" :class="{show : collapseProfile}" style="position: absolute; inset: 0px 0px auto auto; margin: 0px; transform: translate3d(0.8px, 72px, 0px);">
                        <!-- item-->
                        <div class=" dropdown-header noti-title">
                            <h6 class="text-overflow m-0">Welcome !</h6>
                        </div>

                        <!-- item-->
                        <a href="javascript:void(0);" class="dropdown-item">
                            <i class="mdi mdi-account-circle me-1"></i>
                            <span>My Account</span>
                        </a>


                        <!-- item-->
                        <a @click="logout" href="javascript:void(0);" class="dropdown-item">
                            <i class="mdi mdi-logout me-1"></i>
                            <span>{{$t('actions.logout')}}</span>
                        </a>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script setup>
    import { ref, onMounted, computed } from 'vue'
    import { useI18n } from 'vue-i18n'
    import NotificationsNavBar from './NotificationsNavBar.vue'
    import useAuthStore from '../stores/auth.store';

    const authStore = useAuthStore();
    const htmlBar = ref(null)
    const collapseLanguage = ref(false)
    const collapseNotification = ref(false)
    const collapseProfile = ref(false)
    const selectedLanguage = ref('Español')
    const alternativeLanguage = computed(() => {
        return selectedLanguage.value == 'Español' ? 'English' : 'Español'
    })
    const {locale} = useI18n({ useScope: 'global' })

    const languageDropdown = ref(null)
    const notificationDropdown = ref(null)
    const profileDropdown = ref(null)


    onMounted(
        () => {
            htmlBar.value = document.getElementById('settings')
            window.addEventListener('click', listener)
        }
    )

    function getFlag(language){
        if(language == 'Español')
            return '/assets/images/flags/spain.jpg'
        else if(language == 'English'){
            return '/assets/images/flags/us.jpg'
        }
    }

    const toggleMenu = () => {
        // alert('toggle menu')
        
        htmlBar.value.classList.toggle('sidebar-enable')
        if( htmlBar.value.dataset.sidenavSize == 'condensed' ) {
            htmlBar.value.dataset.sidenavSize = 'default'
        } else {
            htmlBar.value.dataset.sidenavSize = 'condensed'
        }

    }
    function changeLanguage(){
        selectedLanguage.value=alternativeLanguage.value
        collapseLanguage.value= !collapseLanguage
        if (selectedLanguage.value == 'Español')
            locale.value = 'es'
        else
            locale.value = 'en'
    }

    function listener(e){
        if (collapseLanguage.value && e.target != languageDropdown.value && !e.composedPath().includes(languageDropdown.value)){
            collapseLanguage.value = false
            return
        }
        else if (collapseNotification.value && e.target != notificationDropdown.value && !e.composedPath().includes(notificationDropdown.value)){
            collapseNotification.value = false
            return
        }
        else if (collapseProfile.value && e.target != profileDropdown.value && !e.composedPath().includes(profileDropdown.value)){
            collapseProfile.value = false
            return
        }
        
    }

    const logout = ()=> {
        authStore.logout()
    }


</script>