<script setup>
import { ref, onMounted, inject } from 'vue'
import { DateTime } from 'luxon'
import { Notificaciones as NotificacionesApi } from '../Api/API_Concentrador'


const htmlBar = ref(null)
const collapseLanguage = ref(false)
const collapseNotification = ref(false)


const notificaciones = inject('notificaciones')
const languageDropdown = ref(null)
const notificationDropdown = ref(null)


const iconos_clases = {
    'question': 'mdi mdi-message-question',
    'order': 'mdi mdi-sale',
    'shipping': 'mdi mdi-truck-delivery',
    'publication': 'mdi mdi-newspaper',
    'payment': 'mdi mdi-cash',
}


onMounted(
    () => {
        htmlBar.value = document.getElementById('settings')
        window.addEventListener('click', listener)
    }
)

function getColorStyle(tipo) {
    return "color: #1d9bb2"
}

function getIcon(tipo) {
    return iconos_clases[tipo] ?? 'ri-information-line'
}

function getTime(time) {
    //formato AM/PM
    const format = 'hh:mm a | dd/MM/yyyy'
    
    //si es nulo 
    if (time == null) {
        return '--:--:-- | --/--/----'
    }
    
    //si time incluye la palabra 'T' es porque es un formato ISO
    if (time.includes('T')) {
        return DateTime.fromISO(time).toFormat(format)
    }else {
        return DateTime.fromSQL(time).toFormat(format)
    }
}

function eliminarNotificacion(id) {
    NotificacionesApi.eliminarNotificacionUnseen(id).then(res => {
        let index = notificaciones.value.findIndex(notificacion => notificacion.id == id)
        notificaciones.value.splice(index, 1)
    })
}

function eliminarNotificaciones() {
    NotificacionesApi.eliminarNotificacionesUnseen().then(res => {
        notificaciones.value = []
    })
}

function getLink(item) {
    let marketplace = -1
    switch (item.marketplace) {
        case 0:
            marketplace = 'mercadolibre'
            break;
        case 1:
            marketplace = 'amazon'
            break;
        case 2:
            marketplace = 'linio'
            break;
        default:
            break;
    }
    let type = ''
    switch (item.type) {
        case 'question':
            type = 'preguntas'
            break;
        case 'order':
            type = 'ventas'
            break;
        case 'shipping':
            type = 'ventas'
            break;
        case 'publication':
            type = 'publicaciones'
            break;
        case 'payment':
            type = 'pagos'
            break;
        default:
            break;
    }
    return `/${marketplace}/${type}?busqueda=${item.id}`
}


function listener(e) {
    if (collapseLanguage.value && e.target != languageDropdown.value && !e.composedPath().includes(languageDropdown.value)) {
        collapseLanguage.value = false
        return
    }
    else if (collapseNotification.value && e.target != notificationDropdown.value && !e.composedPath().includes(notificationDropdown.value)) {
        collapseNotification.value = false
        return
    }
}



</script>

<template>
    <li class="dropdown notification-list" ref="notificationDropdown">
        <a class="nav-link dropdown-toggle arrow-none" href="#" role="button"
            @click="collapseNotification = !collapseNotification">
            <i class="ri-notification-3-line font-22"></i>
            <span v-if="notificaciones.length > 0" class="noti-icon-badge"></span>
        </a>
        <div class="dropdown-menu dropdown-menu-end dropdown-menu-animated dropdown-lg py-0"
            :class="{ show: collapseNotification }"
            style="position: absolute; inset: 0px 0px auto auto; margin: 0px; transform: translate3d(0px, 72px, 0px);">
            <div class="p-2 border-top-0 border-start-0 border-end-0 border-dashed border mb-1">
                <div class="row align-items-center">
                    <div class="col">
                        <h6 class="m-0 font-16 fw-semibold"> {{ $t('notifications', 2) }}</h6>
                    </div>
                    <div class="col-auto">
                        <a @click="eliminarNotificaciones" href="javascript: void(0);"
                            class="text-dark text-decoration-underline">
                            <small>{{ $t('notification.clear') }}</small>
                        </a>
                    </div>
                </div>
            </div>
            <div class="px-3 overflow-auto" style="max-height: 300px;" data-simplebar>
                <router-link :to="getLink(notificacion)" v-for="notificacion in notificaciones"
                   class="dropdown-item p-0 notify-item card read-noti shadow-none mb-2">
                    <div class="card-body">
                        <span class="float-end noti-close-btn text-muted"><i @click="eliminarNotificacion(notificacion.id)"
                                class="mdi mdi-close"></i></span>
                        <div class="d-flex align-items-center">
                            <div class="flex-shrink-0">
                                <i :class="getIcon(notificacion.type)" style="font-size:2.5rem"
                                    :style="getColorStyle(notificacion.type)"></i>
                            </div>
                            <div class="flex-grow-1 text-truncate ms-2">
                                <h5 class="noti-item-title fw-semibold font-14" style="white-space: normal;">{{notificacion.message}}</h5>
                                <small class="fw-normal text-muted">{{getTime(notificacion.date)}}</small>
                                <br />
                                <small class="noti-item-subtitle text-muted" style="white-space: normal">{{ notificacion.message }}</small>
                            </div>
                        </div>
                    </div>
                </router-link>
                <a v-if="notificaciones.length == 0" href="javascript:void(0);"
                    class="dropdown-item p-0 notify-item card read-noti shadow-none mb-2">
                    <div class="card-body">
                        <div class="d-flex align-items-center">
                            <div class="flex-grow-1 text-truncate">
                                <h5 class="noti-item-title fw-semibold font-14 text-center">
                                    {{ $t('notification.no_notifications') }}</h5>
                            </div>
                        </div>
                    </div>
                </a>
                <a href="javascript:void(0);"
                    class="dropdown-item text-center text-primary notify-item border-top border-light py-2">
                    <router-link :to="`/${$t('notifications', 2)}`">{{ $t('notification.history') }}</router-link>
                </a>
            </div>
        </div>
    </li>
</template>