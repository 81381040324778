import { createRouter, createWebHistory } from 'vue-router'
import useAuthStore from '../stores/auth.store';
import useTableStore from '../stores/table.store';
import { Warehouse, Category, Product, ML_Publication} from '../Api/ApiLibrary' 
import app from '../main'
const warehouse = new Warehouse();
const category = new Category();
const product = new Product();
const ml_publication = new ML_Publication();

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('../views/HomeView.vue')
    },
    {
      path: '/about',
      name: 'about',
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import('../views/AboutView.vue')
    },
    {
      path: '/vista1',
      name: 'vista1',
      component: () => import('../views/MiVista.vue')
    },
    {
      path: '/productos',
      name: 'productos',
      component: () => import('../views/ProductosView.vue'),
      params: true
    },
    {
      //404
      path: '/:pathMatch(.*)*',
      name: '404',
      component: () => import('../views/NotFoundView.vue')
    },
    {
      path: '/pedidos',
      name: 'pedidos',
      component: () => import('../views/PedidosView.vue')
    },
    {
      path: '/clientes',
      name: 'clientes',
      component: () => import('../views/ClientesView.vue')
    },
    {
      path: '/mercadolibre/devoluciones',
      name: 'devoluciones_ml',
      component: ()=> import('../views/DevolucionesView.vue')
    },
    {
      path: '/mercadolibre/ventas',
      name: 'ventas_ml',
      component: ()=> import('../views/VentasView.vue')
    },
    {
      path: '/mercadolibre/preguntas',
      name: 'preguntas_ml',
      component: ()=> import('../views/PreguntasView.vue')
    },
    {
      path: '/mercadolibre/pagos',
      name: 'pagos_ml',
      component: ()=> import('../views/PagosView.vue')
    },
    {
      path: '/mercadolibre/publicaciones',
      name: 'publicaciones_ml',
      component: ()=> import('../views/MercadoLibre/ML_Publications.vue')
    },
    {
      path: '/mercadolibre/publicaciones/:id',
      name: 'ficha_publicaciones_ml',
      component: ()=> import('../views/MercadoLibre/FichaPublicacionView.vue')
    },
    {
      path: '/publicaciones',
      name: 'publicaciones',
      component: ()=> import('../views/PublicacionesView.vue'),
      params: true
      },
    {
      path: '/amazon/pagos',
      name: 'pagos_amz',
      component: ()=> import('../views/Amazon/PagosView.vue')
    },
    {
      path: '/amazon/devoluciones',
      name: 'devoluciones_amz',
      component: ()=> import('../views/Amazon/DevolucionesView.vue')
    },
    {
      path: '/amazon/preguntas',
      name: 'preguntas_amz',
      component: ()=> import('../views/Amazon/PreguntasView.vue')
    },
    {
      path: '/amazon/ventas',
      name: 'ventas_amz',
      component: ()=> import('../views/Amazon/VentasView.vue')
    },
    {
      path: '/amazon/publicaciones',
      name: 'publicaciones_amz',
      component: ()=> import('../views/Amazon/AMZPublicationsView.vue')
    },
    {
      path: '/anymarket/uploaded',
      name: 'uploaded_am',
      component: ()=> import('../views/AnyMarket/UploadedView.vue')
    },
    {
      path: '/anymarket/transmissions',
      name: 'transmissions_am',
      component: ()=> import('../views/AnyMarket/TransmissionsView.vue')
    },
    {
      path: '/anymarket/pagos',
      name: 'pagos_am',
      component: ()=> import('../views/Amazon/PagosView.vue')
    },
    {
      path: '/anymarket/devoluciones',
      name: 'devoluciones_am',
      component: ()=> import('../views/Amazon/DevolucionesView.vue')
    },
    {
      path: '/anymarket/preguntas',
      name: 'preguntas_am',
      component: ()=> import('../views/Amazon/PreguntasView.vue')
    },
    {
      path: '/anymarket/ventas',
      name: 'ventas_am',
      component: ()=> import('../views/AnyMarket/SalesView.vue')
    },
    {
      path: '/anymarket/publicaciones',
      name: 'publicaciones_am',
      component: ()=> import('../views/Amazon/AMZPublicationsView.vue')
    },
    {
      path: '/walmart/publicaciones',
      name: 'publicaciones_wal',
      component: ()=> import('../views/Walmart/WALPublicationsView.vue')
    },
    {
      path: '/walmart/publicaciones/:sku',
      name: 'ficha_publicaciones_wal',
      component: ()=> import('../views/Walmart/WALPublicationShow.vue')
    },
    {
      path: '/walmart/ventas',
      name: 'ordenes_wal',
      component: ()=> import('../views/Walmart/WALOrdersView.vue')
    },
    {
      path: '/walmart/devoluciones',
      name: 'devoluciones_wal',
      component: ()=> import('../views/Walmart/WALReturnsView.vue')
    },
    {
      path: '/configuracion',
      name: 'configuracion',
      props: true,
      component: ()=> import('../views/General/ConfiguracionView.vue')
    },
    {
      path: '/configuracion/:plataforma',
      name: 'configuracion_ml',
      props: true,
      component: ()=> import('../views/General/ConfiguracionView.vue')
    },
    {
      path: '/notificaciones',
      name: 'notificaciones',
      component: ()=> import('../views/NotificacionesView.vue')
    },
    {
      path: '/login',
      name: 'login',
      component: ()=> import('../views/LoginView.vue')
    },
    {
      path: '/tareas',
      name: 'tareas',
      component: ()=> import('../views/General/TasksView.vue')
    },
    {
      path: '/usuarios',
      name : 'usuarios',
      component: ()=> import('../views/UsuariosView.vue')
    },
    {
      path: '/productos/crear',
      name: 'nuevo_producto',
      component: ()=> import('../views/General/NuevoProductoView.vue')
    },
    {
      path: '/productos/editar/:id',
      name: 'editar_producto',
      props: true,
      component: ()=> import('../views/General/NuevoProductoView.vue')
    },
    {
      path: '/productos/ver/:id',
      name: 'ver_producto',
      props: true,
      component: ()=> import('../views/General/FichaProducto.vue')
    },
    {
      path: '/tienda/publicaciones',
      name: 'publicaciones_tienda',
      component: ()=> import('../views/Prestashop/PS_Publications.vue')
    },
    {
      path: '/tienda/ventas',
      name: 'ventas_tienda',
      component: ()=> import('../views/Prestashop/PS_OrdersView.vue')
    },
    {
      path: '/images/upload',
      name: 'upload_images',
      component: ()=> import('../views/ImageUploaderView.vue')
    }
  ]
})

router.beforeResolve(async (to, from) => {
  if (to.path.toLowerCase() == '/productos/crear'){
    let promises = [warehouse.list(), category.list()]
    await Promise.all(promises).then(res => {
      app.provide('warehouses', res[0].data)
      app.provide('categories', res[1].data)
    })
  }

  else if(to.path.toLowerCase().match(/\/productos\/editar\/\w+/)){
    let promises = [warehouse.list(), category.list()]
    let product = new Product()
    promises.push(product.get(to.params.id))
    await Promise.all(promises).then(res => {
      app.provide('warehouses', res[0].data)
      app.provide('categories', res[1].data)
      app.provide('product', product)
    })
  }

  else if(to.path.toLowerCase().match(/\/productos\/ver\/\w+/)){
    if (from.path != to.path){
      let product = new Product()
      await product.get(to.params.id).then(res => {
        app.provide('product', product)
      })
    }
  }
  
  else if(to.path.toLowerCase().match(/\/mercadolibre\/publicaciones\/\w+/)){
    let publicacion = new ML_Publication()
    await publicacion.get(to.params.id).then(res => {
      app.provide('publicacion', publicacion)
    })
  }
})

router.beforeEach(async (to,from) => {
  
  const tableStore = useTableStore();
  const authStore = useAuthStore();
  const publicPages = ['/login'];
  const authRequired = !publicPages.includes(to.path);
  let rutaBase = to.path.match(/\/[^/]+/)
  if (rutaBase == null) rutaBase = '/'
  if (authRequired && !authStore.usuario) {
      authStore.returnUrl = to.fullPath;
      return '/login';
  }
  if (from.path != to.path){
    tableStore.setNumeroItems(0);
  }
});

export default router
