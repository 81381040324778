import { defineStore } from 'pinia';
import Credential from '../Api/Credential';
import MercadoLibreService from '../Api/MercadoLibreService';
import { ref } from 'vue';

const _amazonCredentials = new Credential("amazon")
const _mercadolibreCredentials = new Credential("mercadolibre")
const _walmartCredentials = new Credential("walmart") // Asegúrate de que esta es la correcta instancia

export const useMarketplaceCredentials = defineStore('marketplaceCredentials', () => {
    const amazonCredentials = ref({})
    const mercadolibreCredentials = ref({})
    const walmartCredentials = ref({}) // Estado reactivo para las credenciales de Walmart

    const setAmazonCredentials = (credentials) => {
        amazonCredentials.value = credentials
    }
    const setMercadolibreCredentials = (credentials) => {
        mercadolibreCredentials.value = credentials
    }
    const setWalmartCredentials = (credentials) => { // Nueva función para establecer credenciales de Walmart
        walmartCredentials.value = credentials
    }

    (async () => {
        let promises = [
            _amazonCredentials.getCredentials().catch(err => err.response),
            _mercadolibreCredentials.getCredentials().catch(err => err.response),
            _walmartCredentials.getCredentials().catch(err => err.response) // Agregar promesa para Walmart
        ];

        await Promise.all(promises).then(async () => {
            let userInfoPromises = [];
            for (const [key, value] of Object.entries(_mercadolibreCredentials.cuentas)) {
                let user_id = value.data.User_id;
                userInfoPromises.push(MercadoLibreService.getUserInfo(user_id).catch(err => err.response));
            }
            await Promise.all(userInfoPromises).then(res => {
                for (const [key, value] of Object.entries(_mercadolibreCredentials.cuentas)) {
                    value.data.user_info = res[key].data;
                }
            })
            _mercadolibreCredentials.cuentaActiva = _mercadolibreCredentials.cuentas.find(cuenta => cuenta.active);
            _amazonCredentials.cuentaActiva = _amazonCredentials.cuentas.find(cuenta => cuenta.active);
            _walmartCredentials.cuentaActiva = _walmartCredentials.cuentas.find(cuenta => cuenta.active); // Agregar para Walmart
        });

        setAmazonCredentials(_amazonCredentials);
        setMercadolibreCredentials(_mercadolibreCredentials);
        setWalmartCredentials(_walmartCredentials); // Agregar para Walmart
    })();

    return {
        amazonCredentials,
        mercadolibreCredentials,
        walmartCredentials, // Agregar walmartCredentials al estado expuesto
        setAmazonCredentials,
        setMercadolibreCredentials,
        setWalmartCredentials // Exponer setWalmartCredentials
    }
});

export default useMarketplaceCredentials;