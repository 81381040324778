import BaseClass from './BaseClass'
import axios from '../axios'

export default class Category extends BaseClass {
    Rowid
    Entity
    FkParent
    Label
    RefExt
    Type
    Description
    Color
    FkSoc
    Visible
    ImportKey
    SamePrice
    LlxCategorieAccounts
    LlxCategorieContacts
    LlxCategorieFournisseurs
    LlxCategorieLangs
    LlxCategorieProducts
    LlxCategorieProjects
    LlxCategorieSocietes
    LlxCategorieUsers
    LlxCategorieWarehouses
    FkMembers

    constructor() {
        super('api/erp/categories')
    }

    async list(){
        let url = `${this.urlBase}`
        const res = await axios.get(url)
        return res
    } 
}