import axios from '../axios';

export default class Credential {
    marketplace
    cuentas = []
    
    constructor(marketplace){
        this.marketplace = marketplace
    }

    async authorize(data){
        await axios.post(`api/${this.marketplace}/authorize`, data).then(res => {
            this.cuentas.push(res.data)
        })
    }

    async getCredentials(){
        const res = await axios.get(`api/${this.marketplace}/get_credentials`)
        this.cuentas = res.data
    }

    async deleteCredential(id){
        await axios.delete(`api/${this.marketplace}/credentials/${id}`)
    }

    async setActive(id){
        await axios.get(`api/${this.marketplace}/credentials/${id}/set_active`)
    }

    async syncStock(id){
        await axios.get(`api/${this.marketplace}/credentials/${id}/sync_stock`)
    }

    async storeCredentials(data){
        await axios.post(`api/${this.marketplace}/credentials/`, data)
    }
}