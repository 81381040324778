<template>
    <div class="wrapper">
        <template v-if="authStore.usuario">
            <Base />
        </template>
        <template v-else>
            <router-view />
        </template>
    </div>
</template>

<script setup>
import { ref, provide } from 'vue';
import Base from './components/Base.vue';
import useAuthStore from './stores/auth.store';

const authStore = useAuthStore();


const ajustesVisibles = ref(false);
provide('mostrarAjustes', ajustesVisibles);

</script>
<style>
    .alert-success {
        background-color: #D4F4E4;
    }
    .alert-danger {
        background-color: #FFE1E1;
    }
    :root {
        --popper-theme-background-color: #fff;
        --popper-theme-background-color-hover: #fff;
        --popper-theme-text-color: #000;
        --popper-theme-border-width: 0px;
        --popper-theme-border-style: solid;
        --popper-theme-border-radius: 6px;
        --popper-theme-padding: 10px;
        --popper-theme-box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.25);
    }
    
    .multiselect__tag{
        background: #727cf5 !important;
    }
    .multiselect__option--highlight{
        background: #727cf5 !important;
    }
    .multiselect__spinner:after,.multiselect__spinner:before{
        background: #727cf5 !important;
    }
    .multiselect__option--highlight:after{
        background: #727cf5 !important;
    }

    ::-webkit-scrollbar {
        width: 6px;
        height: 6px;
    }
    ::-webkit-scrollbar-track {
        border-radius: 10px;
        background: rgba(0, 0, 0, 0.1);
    }
    ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: rgba(0, 0, 0, 0.2);
    }
    ::-webkit-scrollbar-thumb:hover {
        background: rgba(0, 0, 0, 0.4);
    }
    ::-webkit-scrollbar-thumb:active {
        background: rgba(0, 0, 0, 0.9);
    }

</style>