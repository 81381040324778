import axios from "../axios";

class Api {
    static async post(data){
        const res = await axios.post(this.urlBase, data)
        return res
    }

    static async get(obj = {}, extra_filters = {}){
        const sortorder = obj.sortorder || 'ASC'
        const sortfield = obj.sortfield || 'rowid'
        const limit = obj.limit || 5
        const page = obj.page || 0

        let url = `${this.urlBase}?sortfield=t.${sortfield}&sortorder=${sortorder}&limit=${limit}&page=${page}&pagination_data=true`
        for (const [key, value] of Object.entries(extra_filters)) {
            url += `&${key}=${value}`
        }
        
        const res = await axios.get(url, obj)

        return res
    }

    static async put(id, data){
        const res = await axios.put(`${this.urlBase}/${id}`, data)
        return res
    }
}

export class PublicacionesPendientes extends Api{
    static urlBase = 'mercado_libre/publicaciones/pendientes/'
}

export class PublicacionesRealizadas extends Api{
    static urlBase = 'mercado_libre/publicaciones/publicadas/'
}

export class PublicacionesML {
    
    static urlBase = 'mercado_libre/publicaciones/'

    static async validarPublicacion(data){
        const res = await axios.post(this.urlBase+'validar/', data, {headers: {'Content-Type': 'application/json'}})
        return res
    }   

    static async validar_masivo(ids){
        const res = await axios.post(this.urlBase+'validar_masivo/', {publicaciones: ids})
        return res
    }

    static async publicar(id, data){
        const res = await axios.post(this.urlBase+id+'/publicar/', data, {headers: {'Content-Type': 'application/json'}})
        return res
    }

    static async publicar_masivo(ids){
        const res = await axios.post(this.urlBase+'publicar_masivo/', {publicaciones: ids})
        return res
    }
}

export class PreguntasML extends Api {
    static urlBase = 'mercado_libre/preguntas/'
    
    static async responder(id, respuesta){
        let body = {
            respuesta: respuesta
        }
        const res = await axios.post(this.urlBase+id+'/responder/', body)
        return res.data
    }
}

export class VentasML extends Api{
    static urlBase = 'mercado_libre/ordenes/'

    static async send_venta(id){
        const res = await axios.post(`prueba/?id=${id}`)
        return res
    }
    
}

export class Clientes extends Api{
    static urlBase = "api/clientes/"

    static async getClientes(estado="", filtros={}, page=1, limit=5) {
        if (estado!="") {
            filtros['ordenar'] = {
                'campo': estado,
                'orden': 'asc'
            }
        }
        let res = null
        if(Object.values(filtros).every(x => x === null || x === '')){
            res = await axios.get(this.urlBase+'?page='+page+'&limit='+limit)
        }
        else{
            res = await axios.post(this.urlBase+'q/?page='+page+'&limit='+limit,filtros,{headers: {'Content-Type': 'application/json'}})
        }
        
        return res
    }

    static async guardarCliente(data){
        const res = await axios.post(this.urlBase, data,{headers: {'Content-Type': 'application/json'}})
        return res
    }
}

export class Pedidos extends Api{
    static urlBase = "api/pedidos/"

    static async guardar(data, idCliente){
        let body = data
        body.detalles_pedido?.forEach((item)=> {
            item.descuento = 0;
        });
        body['cliente'] = idCliente
        const res = await axios.post(this.urlBase, body,{headers: {'Content-Type': 'application/json'}})
        return res
    }

    static async getPedidos(estado="", filtros={}, page=1, limit=5){
        if (estado!="") {
            filtros['ordenar'] = {
                'campo': estado,
                'orden': 'asc'
            }
        }
        let res = null
        if(Object.values(filtros).every(x => x === null || x === '')){
            res = await axios.get('http://localhost:8000/api/pedidos/?page='+page+'&limit='+limit)
        }
        else{
            res = await axios.post('http://localhost:8000/api/pedidos/q/?page='+page+'&limit='+limit,filtros,{headers: {'Content-Type': 'application/json'}})
        }
        return res
    }

    static async getDetalles(id){
        let res = await axios.get('http://localhost:8000/api/pedidos/'+id+'/getDetails/')
        return res
    }
}

export class Notificaciones{
    static baseUrl = 'api/notifications/';

    static async get(){
        const response = await axios.get(this.baseUrl);
        return response;
    }

    static async getHistory(){
        const response = await axios.get(this.baseUrl + 'history/');
        return response.data;
    }
    static async eliminarNotificacionSeen(id){
        const response = await axios.delete(this.baseUrl + id + '/');
        return response.data;
    }

    static async eliminarNotificacionesSeen(){
        const response = await axios.delete(this.baseUrl+'delete_all/');
        return response.data;
    }

    static async eliminarNotificacionUnseen(id){
        const response = await axios.delete(this.baseUrl + id + '/delete_unseen/');
        return response.data;
    }

    static async eliminarNotificacionesUnseen(){
        const response = await axios.delete(this.baseUrl+'delete_all_unseen/');
        return response.data;
    }

}

export class Sesion {
    static baseUrl = 'api/login/';

    static async iniciarSesion(username, password){
        const response = await axios.post(this.baseUrl, {username: username, password: password}, {headers: {'Content-Type': 'application/json'}});
        return response.data;
    }

    static async refreshToken(refresh){
        const response = await axios.post(this.baseUrl + 'refresh/', {refreshToken: refresh}, {headers: {'Content-Type': 'application/json'}});
        return response.data;
    }

}

export class Tasks {
    static baseUrl = 'api/tasks/';

    static async get(){
        const response = await axios.get(this.baseUrl + 'all/');
        return response.data;
    }
}

export class Usuarios extends Api{
    static urlBase = "api/usuarios/"
}

export class PagosML extends Api {
    static urlBase = "mercado_libre/pagos/"
}



export default { VentasML, PublicacionesML, PreguntasML}
