<template>
    <li class="side-nav-item">
        <router-link data-bs-toggle="collapse" :to="props.url || '#'" :aria-expanded="expandido"
            aria-controls="sidebarEcommerce" :class="{
                'side-nav-link': true,
                'collapsed': !expandido
            }" @click="toggleMenu">

            <!-- icono -->
            <i v-if="props.icono" :class="props.icono"></i>
            <img v-else-if="props.imagen" :src="props.imagen" alt="imagen">
            <span> {{ props.titulo}} </span>

            <!-- flecha sub menus -->
            <span v-if="props.submenus" class="menu-arrow"></span>

            <span v-if="props.badgeClass || props.badgeText" :class="`${props.badgeClass} float-end`">{{props.badgeText}}</span>
        </router-link>
        <div :class="{collapse: true, show: expandido}" id="sidebarEcommerce" v-if="props.submenus">
            <ul class="side-nav-second-level">
                <li v-for="submenu in props.submenus" :key="submenu.id">
                    <router-link :to="submenu.url">{{ submenu.titulo }}</router-link>
                </li>
            </ul>
        </div>
    </li>
</template>

<script setup>
import { ref } from 'vue'
// import router from '../router';
import { useRouter } from 'vue-router';
const expandido = ref(false)
const router = useRouter()



const props = defineProps({
    titulo: String,
    icono: String,
    submenus: Array,
    url: String,
    badgeClass: String,
    badgeText: String,
    imagen: String
})


const toggleMenu = () => {
    if (props.submenus) {
        expandido.value = !expandido.value
    }else{
        // alert(props.url)
        //ir a la url con el router de vue
        if(props.url){
            router.push(props.url)
        }else{
            router.push('/404')
        }
    }
}


</script>

<style scoped>
    .side-nav .side-nav-link img {
        display: inline-block;
        vertical-align: middle;
        text-align: center;
        width: calc(var(--ct-leftbar-width-sm) - var(--ct-menu-item-padding-x) * 2);
        min-width: calc(var(--ct-leftbar-width-sm) - var(--ct-menu-item-padding-x) * 2);
        font-size: var(--ct-menu-item-icon-size);
        line-height: var(--ct-menu-item-icon-size); 
        padding: 0px var(--ct-menu-item-padding-x);
    }
</style>