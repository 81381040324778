import BaseClass from './BaseClass'
import axios from '../axios'
export default class Product extends BaseClass {

    //Campos
    Rowid
    Ref
    OldRef
    Entity
    RefExt
    Datec
    Tms
    FkParent
    Label
    Description
    Note
    Customcode
    FkCountry
    Price
    PriceTtc
    PriceMin
    PriceMinTtc
    PriceBaseType
    CostPrice
    DefaultVatCode
    TvaTx
    Recuperableonly
    Localtax1Tx
    Localtax1Type
    Localtax2Tx
    Localtax2Type
    FkUserAuthor
    FkUserModif
    Tosell
    Tobuy
    Onportal
    Tobatch
    FkProductType
    Duration
    SeuilStockAlerte
    Url
    Barcode
    FkBarcodeType
    AccountancyCodeSell
    AccountancyCodeSellIntra
    AccountancyCodeSellExport
    AccountancyCodeBuy
    Partnumber
    NetMeasure
    NetMeasureUnits
    Weight
    WeightUnits
    Length
    LengthUnits
    Width
    WidthUnits
    Height
    HeightUnits
    Surface
    SurfaceUnits
    Volume
    VolumeUnits
    Stock
    Pmp
    Fifo
    Lifo
    FkDefaultWarehouse
    Canvas
    Finished
    Hidden
    ImportKey
    ModelPdf
    FkPriceExpression
    Desiredstock
    FkUnit
    PriceAutogen
    FkProject
    Gain
    BasePrice
    BasePriceTtc
    BasePriceMin
    BasePriceMinTtc
    Revicedstock
    DesiredstockPrincipal
    DesiredstockGpe
    Wh1percent
    Wh2percent
    LocationMatriz
    LocationGpe
    UnitEntrada
    UnitSalida
    DateCompra
    DateVenta
    LocationMatriz2
    LocationGpe2
    Wh1Limit
    Wh2Limit
    Rotation
    FkOc
    PrecioTemp
    GainTemp
    CostPriceTemp
    Exentoiva
    DescMax
    CantDentroEmpaque
    Empaque
    DateLimDiscount
    TempDiscount
    FkBarcodeTypeNavigation
    FkCountryNavigation
    FkDefaultWarehouseNavigation
    FkUnitNavigation
    LlxCategorieProducts
    LlxContratdets
    LlxMrpProductions
    LlxProductCustomerPrices
    LlxProductFournisseurPrices
    LlxProductLangs
    LlxProductPrices
    LlxProductReplenish

    constructor() {
        super('api/platform/products/')
    }

    async deleteImage(name) {
        const res = await axios.delete(`${this.urlBase}${this.rowId}/images/${name}`)
        return res
    }

}