import axios from '../axios';
import defaultAxios from 'axios'
export default class MercadoLibreService
{
    static async getShippingMethodsByCategory(category, attributes){
        const res = await axios.post(`api/mercadolibre/service/shipping_methods/${category}`, attributes)
        return res
    }

    static async getShippingMethodsByCategoryAndAccount(id,category, attributes){
        const res = await axios.post(`api/mercadolibre/service/${id}/shipping_methods/${category}`, attributes)
        return res
    }
    static async getPublicationTypesByCategory(category){
        const res = await axios.get(`api/mercadolibre/service/publication_types/${category}`)
        return res
    }

    static async getFeesByCategory(category, logistic_type, price){
        const res = await defaultAxios.get(`https://api.mercadolibre.com/sites/MLM/listing_prices?price=${price}&currency_id=MXN&logistic_type=${logistic_type}&category_id=${category}`)
        return res
    }

    static async getUserInfo(user_id){
        const res = await defaultAxios.get(`https://api.mercadolibre.com/users/${user_id}`)
        return res
    }

    static async searchProductByGtin(gtin){
        const res = await defaultAxios.get(`https://api.mercadolibre.com/products/search?status=active&site_id=MLM&product_identifier=${gtin}`)
        return res
    }

    static async predictCategoryByTitle(title){
        const res = await defaultAxios.get(`https://api.mercadolibre.com/sites/MLM/domain_discovery/search?limit=1&q=${title}`)
        return res
    }

    static async getProductInfoById(id){
        const res = await axios.get(`api/mercadolibre/service/products/${id}`)
        return res
    }

    static async getShippingSchedule(id){
        const res = await axios.get(`api/mercadolibre/service/${id}/shipping/schedule`)
        return res
    }
}