import { defineStore } from 'pinia';

export const useTableStore = defineStore({
    id: 'table',
    state: () => ({
        numero_items: 0       
    }),
    actions: {
        setNumeroItems(numero_items) {
            this.numero_items = numero_items;
        },

        setFilterActive(filter_active) {
            this.filter_active = filter_active;
        },
        
        setFocus(focus) {
            this.focus = focus;
        }
    }
});

export default useTableStore;