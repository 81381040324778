import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import Toast, { POSITION } from "vue-toastification";
import "vue-toastification/dist/index.css";
import i18n from './langs/i18n'
import { createPinia } from 'pinia'
import VueApexCharts from "vue3-apexcharts";
import "vue-multiselect/dist/vue-multiselect.css"
import "vue3-toastify/dist/index.css";
import Vue3Toastify, { type ToastContainerOptions } from 'vue3-toastify';
import VueUploadComponent from 'vue-upload-component'
import { createBootstrap } from 'bootstrap-vue-next'

const app = createApp(App)
const pinia = createPinia()


app.use(router)

app.use(Toast, {
    position: POSITION.TOP_RIGHT,
    transition: "Vue-Toastification__fade"
});

app.use(Vue3Toastify, {
    autoClose: 3000,
} as ToastContainerOptions);

app.component('file-upload', VueUploadComponent)
app.use(i18n)
app.use(pinia)
app.use(createBootstrap())

app.use(VueApexCharts);

app.mount('#app')

export default app
