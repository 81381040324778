<script setup>
    import NavBar from './NavBar.vue';
    import { ref, provide, onMounted } from 'vue';
    import { Notificaciones as NotificacionesApi } from '../Api/API_Concentrador';
    import {useMarketplaceCredentials} from '../stores/marketplaceCredentials.store'; 
    import i18n from '../langs/i18n';
    import LeftBar from './LeftBar.vue';


    const htmlBar = ref(null);
    const ajustesVisibles = ref(false);
    const notificaciones = ref([]);
    const marketplaceCredentials = useMarketplaceCredentials();

    provide('mostrarAjustes', ajustesVisibles);
    provide('notificaciones', notificaciones);

    function notificacionNavegador(message) {
        const mensaje_notificacion = message;
        if (!("Notification" in window)) {
            alert("Tu navegador no soporta notificaciones");
        } else if (Notification.permission === "granted") {
            const notification = new Notification(mensaje_notificacion);
        } else if (Notification.permission !== "denied") {
            Notification.requestPermission().then((permission) => {
                if (permission === "granted") {
                    const notification = new Notification(mensaje_notificacion);
                }
            });
        }
    }

    function reconectar(){
        //esta funcion va incremetando el tiempo de espera para reconectar el websocket
        //convertir a entero
        let intentos = parseInt(localStorage.getItem('intentos_websocket') || '0')

        if(intentos > 3){
            alert('No se pudo conectar al socket de notificaciones reinicie la pagina')
            return -1
        }

        setTimeout(() => {
            intentos = intentos + 1
            console.log('Intento de reconexión: ' + intentos)
            localStorage.setItem('intentos_websocket', intentos.toString())
            conectarSocket()
        }, intentos * 1000);
    }

    function conectarSocket(){
        NotificacionesApi.get().then((res) => {
            notificaciones.value = res.data.map(n=> n.data)
        });
        const url = import.meta.env.VITE_WEBSOCKET_URL

        let ws = new WebSocket(url);
        ws.onopen = function (e) {
            console.log('Conectado al websocket');
        };
        ws.onmessage = function (e) {
            console.log('Mensaje recibido: ', e.data)
            try {
                let data = JSON.parse(e.data)
                notificacionNavegador(data.message)
                notificaciones.value.unshift(data);
            } catch (error) {
                console.log('Error al parsear el mensaje del websocket')
            }
        };

        ws.close = function (e) {
            console.log('Desconectado del websocket');
            reconectar()
        };

        ws.onerror = function (e) {
            console.log('Error en el websocket');
            reconectar()
        };
    }

    onMounted(() => {
        // @ts-ignore
        htmlBar.value = document.getElementById('settings');
        localStorage.setItem('intentos_websocket', '0')
        conectarSocket()
    });

</script>


<template>
    <NavBar />
    <LeftBar />
    <div class="content-page">
        <div class="content">
            <router-view :key="$route.fullPath" v-slot="{ Component }">
                <component :is="Component" />
            </router-view>
        </div>
    </div>
</template>