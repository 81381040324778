import BaseClass from './BaseClass'
import axios from '../axios'

export default class Warehouse extends BaseClass {
    libelle
    label
    description
    statut
    lieu
    address
    zip
    town
    phone
    fax
    fk_parent
    fk_project
    id
    entity
    validateFieldsErrors
    import_key
    array_options
    array_languages
    linkedObjectsIds
    canvas
    contact_id
    user
    origin
    origin_id
    ref
    ref_ext
    status
    country_id
    country_code
    state_id
    region_id
    barcode_type
    barcode_type_coder
    mode_reglement_id
    cond_reglement_id
    demand_reason_id
    transport_mode_id
    shipping_method_id
    model_pdf
    last_main_doc
    fk_bank
    fk_account
    note_public
    note_private
    total_ht
    total_tva
    total_localtax1
    total_localtax2
    total_ttc
    lines
    name
    lastname
    firstname
    civility_id
    date_creation
    date_validation
    date_modification
    specimen

    constructor() {
        super('api/erp/warehouses')
    }

    async list(){
        let url = `${this.urlBase}`
        const res = await axios.get(url)
        return res
    } 
}